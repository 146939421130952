import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LogoutButton from '../authentication/logout';
import { withUser } from '../session';
import Avatar from '@material-ui/core/Avatar';
import { Link as RouterLink } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const linkMap = [
    { text: "Home", icon: <HomeIcon />, link: "/home", right: true },
    { text: "Namensliste", icon: <PeopleIcon />, link: "/users", right: "mitgliederliste" },
    { text: "Dokumente", icon: <DescriptionIcon />, link: "/documents", right: "dokumente" },
    { text: "Termine", icon: <EventIcon />, link: "/events", right: "termine" },
    { text: "Diensteinteilung", icon: <AssignmentIndIcon />, link: "/dienste", right: "dienste" },
    { text: "Mailrobot", icon: <ContactMailIcon />, link: "/mailrobot", right: "mailrobot" },
];

const settingMap = [
    { text: "Eigene Daten", icon: <AccountCircleIcon />, link: "/personaldata", right: true },
    { text: "Login Daten", icon: <SettingsIcon />, link: "/settings", right: true },
    { text: "Registrierungen", icon: <MarkunreadMailboxIcon />, link: "/registerrequests", right: "admin" },
    { text: "Zugriffsrechte", icon: <AccessibilityIcon />, link: "/accessrights", right: "admin" }
];

function ListItemLink(props) {
    const { icon, primary, to } = props;

    return (
        <li>
            <ListItem button component={RouterLink} to={to}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1
    }
}));

function Navigation(props) {
    const { container, title, user } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let lastname, firstname = "";
    try {
        const res = JSON.parse(user.displayName);
        lastname = res.lastname;
        firstname = res.firstname;
    } catch (error) { }

    const accessRights = user ? user.claims || {} : {};

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            {firstname ?
                <ListItem button key="user">
                    <ListItemIcon><Avatar>{(firstname.charAt(0) || "?").toUpperCase()}</Avatar></ListItemIcon>
                    <ListItemText primary={firstname} secondary={lastname} />
                </ListItem> : null}
            <Divider />
            <List>
                {linkMap
                    .filter(l => l.right === true || accessRights[l.right] === true || accessRights.admin === true || (l.right === "dienste" && accessRights.extern === true))
                    .map((item, index) => (
                        <ListItemLink to={item.link} primary={item.text} icon={item.icon} key={item.text} />
                    ))}
            </List>
            <Divider />
            <List>
                {settingMap
                    .filter(l => !accessRights.extern && (l.right === true || accessRights[l.right] === true || accessRights.admin === true))
                    .map((item, index) => (
                        <ListItemLink to={item.link} primary={item.text} icon={item.icon} key={item.text} />
                    ))}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            {user && user.claims.accepted ? <React.Fragment><CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title} noWrap>
                            {title}
                        </Typography>
                        <LogoutButton color="inherit">Logout</LogoutButton>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav></React.Fragment> : null}
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}

export default withUser(Navigation);