import React from 'react'
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import { compose } from 'recompose';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    withRouter
} from "react-router";

const styles = theme => ({
    form: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    },
    header: {
        '& .MuiTypography-root': {
            color: "#ffffff",

        },
        backgroundColor: theme.palette.primary.main
    },
    button: {
        marginRight: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(1)
    }
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false
        };
    }

    onChange = (value, prop) => {
        this.setState({
            [prop]: value,
            errorUsername: false,
            errorPassword: false
        });
    }

    componentDidUpdate = () => {
        if (this.props.user) {
            const { from } = this.props.location.state || { from: { pathname: "/home" } };
            if (!this.props.user.claims.accepted) {
                if (from.pathname === '/finishregister') this.props.history.replace(from);
                else this.props.history.replace('/finishregister');
            } else {
                if(from.pathname === '/') from.pathname = '/home';
                this.props.history.replace(from);
            }
        }
    }

    onLogin = async () => {
        this.setState({
            loading: true
        });

        const { username, password } = this.state;

        try {
            let email = username;
            if (!email.includes('@')) {
                const res = await this.props.firebase.getEmail({ username, password });
                email = res.data;
            }

            await this.props.firebase.auth.signInWithEmailAndPassword(email, password);

            this.props.history.replace('/home');

            this.setState({
                loading: false
            });
        } catch (error) {
            if(error.code === "failed-precondition") {
                error.code = error.message;
            }

            const errorUsername = error.code === "auth/invalid-uid" || error.code === "auth/user-not-found";
            const errorPassword = error.code === "auth/wrong-password" || error.code === "auth/user-not-found";

            this.setState({
                loading: false,
                errorUsername,
                errorPassword
            });
            console.log(error);
        }
    }

    onPasswordForget = () => {
        this.props.history.replace('/passwordforget');
    }

    onRegister = () => {
        this.props.history.replace('/register');
    }

    render() {
        const { classes } = this.props;

        return <Dialog open={true}>
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Für diese Webseite ist ein Login erforderlich.
                </DialogContentText>
                <Fade in={this.state.loading}><LinearProgress /></Fade>
                <Fade in={!this.state.loading}>
                    <React.Fragment>
                        <TextField
                            required
                            error={this.state.errorUsername}
                            label="Mitgliedsnummer / Benutzername / eMail"
                            className={classes.textField}
                            onChange={(e) => this.onChange(e.target.value, "username")}
                        />
                        <TextField
                            required
                            error={this.state.errorPassword}
                            label="Passwort"
                            type="password"
                            className={classes.textField}
                            onChange={(e) => this.onChange(e.target.value, "password")}
                        />
                    </React.Fragment>
                </Fade>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={this.onRegister}
                    className={classes.button}>
                    Registrieren</Button>
                <Button
                    onClick={this.onPasswordForget}
                    className={classes.button}>Passwort vergessen</Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={this.state.loading}
                    onClick={this.onLogin}>Login</Button>
            </DialogActions>
        </Dialog>
    }
}

export default compose(withRouter, withStyles(styles), withFirebase, withUser)(Login);