import UserList from './userlist.js';
import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import LinearProgress from '@material-ui/core/LinearProgress';

class UserBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            users: [],
            checked: false
        }

        this.docRef = props.firebase.db.collection("documents").doc("mitgliederliste");
    }

    componentDidMount() {
        this.updateList();
    }

    updateList = () => {
        this.docRef.get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    const keys = Object.keys(data).filter(k => data[k]);
                    const users = keys.map(k => ({
                        uid: k,
                        ...data[k],
                        birthday: data[k].birthday ? data[k].birthday.toDate() : null,
                    }));
                    this.setState({
                        users,
                        loading: false
                    })
                }
            }).catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

    handleSortChange = () => {
        this.setState({
            checked: !this.state.checked
        })
    }

    render() {
        let sortedUsers;
        if (this.state.checked) {
            sortedUsers = this.state.users.sort((a,b) => {
                const i = new Date(a.birthday)
                i.setFullYear(2000)
                const j = new Date(b.birthday)
                j.setFullYear(2000)

                return i - j
            }).map(u => ({
                ...u,
                firstChar: u.birthday ? (u.birthday.getMonth()+1).toString() : "#"
            }));
        } else {
            sortedUsers = this.state.users.sort((a,b) => a.lastname.localeCompare(b.lastname)).map(u => ({
                ...u,
                firstChar: (u.lastname || "#").charAt(0).toUpperCase(),
            }));
        }

        return this.state.loading ?
            <LinearProgress /> :
            <UserList
                users={sortedUsers}
                handleSortChange={this.handleSortChange}
                checked={this.state.checked}
            />;
    }
}

export default compose(withFirebase)(UserBase);