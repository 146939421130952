import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
}));

export default function Settings({ onBlur, onChange, email, password, submit, error }) {
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField error={error} required name="email" label="eMail" value={email} onChange={onChange} onBlur={onBlur} />
                <TextField error={error} required name="password" label="Password" type="password" value={password} onChange={onChange} onBlur={onBlur} />
                <Button onClick={submit} variant="contained" color="primary">
                    eMail und Passwort aktualisieren
                </Button>
            </div>
        </form>
    );
}