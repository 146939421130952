import React from 'react';
import Documents from './documents';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withUser } from '../session';

class DocumentsBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            open: false,
            loading: true
        }

        this.inputRef = React.createRef();
        this.storageRef = props.firebase.storage.ref();

        this.updateFileList();
    }

    updateFileList = () => {
        var folders = [];
        this.storageRef.child("/").listAll().then((res) => {
            folders = res.prefixes.map((p, i) => ({
                id: "folder-" + i,
                name: p.name,
                path: p.fullPath
            }));
        }).then(() => {
            const promises = folders.map(folder => this.storageRef.child("/" + folder.name).listAll())
            return Promise.all(promises);
        }).then(results => {
            results.forEach((r, i) => {
                folders[i].files = r.items.map(s => ({ id: "file-" + r.name + "-" + s.name, name: s.name, path: s.fullPath }));
            })

            this.setState({ files: folders, loading: false });
        }).catch(function (error) {
            console.log(error);
        });
    }

    download = (e) => {
        this.storageRef.child(e).getDownloadURL().then(function(url) {
            // `url` is the download URL for 'images/stars.jpg'
            window.open(url,'_blank');
          }).catch(function(error) {
            console.log(error);
          });
    }

    onDelete = (e) => {
        this.storageRef.child(e).delete().then(() => {
            this.updateFileList();
        }).catch((err) => {
            console.log(err);
        });
    }

    addFolderClick = () => {
        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    onBlur = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = () => {
        const { files, name } = this.state;
        const tmp = { id: "folder-" + name, name: name, files: [], path: name };
        files.push(tmp);
        this.setState({ files, open: false });
    }

    onUpload = (path) => {
        this.setState({ path });
        this.inputRef.current.click();
    }

    onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const { path } = this.state;
        var file = event.target.files[0];
        if (file && path) {
            const storageRef = this.storageRef.child(path + "/" + file.name);
            storageRef.put(file).then((snapshot) => {
                this.updateFileList()
            }).catch(error => {
                console.log(error);
            });
        }
    }

    render() {
        return this.state.loading ? <LinearProgress /> : <React.Fragment>
            <Documents
                fileList={this.state.files}
                onDelete={this.onDelete}
                addFolderClick={this.addFolderClick}
                open={this.state.open}
                handleClose={this.handleClose}
                handleSubmit={this.handleSubmit}
                onBlur={this.onBlur}
                onUpload={this.onUpload}
                user={this.props.user}
                download={this.download}
            />
            <input
                id="myFileInput"
                type="file"
                ref={this.inputRef}
                style={{ display: 'none' }}
                onChange={this.onChangeFile}
            />
        </React.Fragment>;
    }
}

export default compose(withUser, withFirebase)(DocumentsBase);