import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    text: {
        display: "inline-block",
        width: "100px"
    },
    chip: {
        margin: theme.spacing(1)
    }
}));

export default function AlignItemsList({ accessRights, handleToggle }) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(-1);

    const handleClickOpen = e => {
        setOpen(e);
    };

    const handleClose = () => {
        setOpen(-1);
    };

    return (
        <React.Fragment>
            <List className={classes.root}>
                {accessRights.map((u, i) => <React.Fragment key={i}>
                    <ListItem button onClick={() => handleClickOpen(i)} alignItems="flex-start">
                        <ListItemAvatar>
                            {i === 0 || accessRights[i - 1].firstChar !== u.firstChar ? <Avatar>{u.firstChar}</Avatar> : <div></div>}
                        </ListItemAvatar>
                        <ListItemText
                            primary={u.firstname + " " + u.lastname}
                            secondary={u.verein}
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </React.Fragment>)}
            </List>
            {open !== -1 ? <Dialog
                open={open !== -1}
                onClose={handleClose}>
                <DialogTitle>{accessRights[open].firstname + " " + accessRights[open].lastname}</DialogTitle>
                <DialogContent>
                    <Chip className={classes.chip} label="Admin" color={accessRights[open].admin ? "primary" : "secondary"} onClick={() => handleToggle(open, "admin")} />
                    <Chip className={classes.chip} label="Mitgliederliste" color={accessRights[open].mitgliederliste ? "primary" : "secondary"} onClick={() => handleToggle(open, "mitgliederliste")} />
                    <Chip className={classes.chip} label="Dienste" color={accessRights[open].dienste ? "primary" : "secondary"} onClick={() => handleToggle(open, "dienste")} />
                    <Chip className={classes.chip} label="Dokumente" color={accessRights[open].dokumente ? "primary" : "secondary"} onClick={() => handleToggle(open, "dokumente")} />
                    <Chip className={classes.chip} label="Dokumente Admin" color={accessRights[open].dokumente_admin ? "primary" : "secondary"} onClick={() => handleToggle(open, "dokumente_admin")} />
                    <Chip className={classes.chip} label="Termine" color={accessRights[open].termine ? "primary" : "secondary"} onClick={() => handleToggle(open, "termine")} />
                    <Chip className={classes.chip} label="Termine Admin" color={accessRights[open].termine_admin ? "primary" : "secondary"} onClick={() => handleToggle(open, "termine_admin")} />
                    <Chip className={classes.chip} label="Mailrobot" color={accessRights[open].mailrobot ? "primary" : "secondary"} onClick={() => handleToggle(open, "mailrobot")} />
                    <Chip className={classes.chip} label="Mailrobot Erweitert" color={accessRights[open].mailrobot_admin ? "primary" : "secondary"} onClick={() => handleToggle(open, "mailrobot_admin")} />
                    <Chip className={classes.chip} label="Extern" color={accessRights[open].extern ? "primary" : "secondary"} onClick={() => handleToggle(open, "extern")} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Schliessen
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </React.Fragment>
    );
}