import React from 'react';
import Events from './events';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import LinearProgress from '@material-ui/core/LinearProgress';

class EventBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            events: []
        }

        this.colRef = props.firebase.db.collection("events");
        this.updateList();
    }

    updateList = () => {
        const events = [];
        this.colRef.where("date", ">=", new Date(new Date().setDate(new Date().getDate() - 1))).orderBy("date", "desc")
            .get()
            .then((querySnapshot) => {
                return querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    const { title, date, description } = doc.data();
                    const uid = doc.id;
                    events.push({
                        title, date, description, uid
                    });
                });
            }).then(() => {
                this.setState({
                    events,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

    addEvent = () => {
        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleDateChange = (e) => {
        this.setState({
            date: e
        });
    }

    onBlur = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleAdd = () => {
        const { title, date, description } = this.state;
        if (title && date && description && title.length > 1 && description.length > 1) {
            this.colRef.add({
                title: this.state.title,
                date: this.props.firebase.fromDate(this.state.date),
                description: this.state.description
            }).then(() => {
                this.setState({
                    open: false,
                    error: false
                });
                this.updateList();
            }).catch((error) => {
                this.setState({
                    error: true
                });
                console.log(error);
            });
        } else {
            this.setState({
                error: true
            });
        }
    }

    onDelete = uid => {
        this.colRef.doc(uid).delete().then(() => {
            this.updateList();
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {
        const { user } = this.props;
        const canEdit = user && user.claims && (user.claims.termine_admin || user.claims.admin);

        return this.state.loading ?
            <LinearProgress /> :
            <Events
                events={this.state.events}
                open={this.state.open}
                error={this.state.error}
                addEvent={this.addEvent}
                handleClose={this.handleClose}
                handleDateChange={this.handleDateChange}
                onBlur={this.onBlur}
                handleAdd={this.handleAdd}
                onDelete={this.onDelete}
                date={this.state.date}
                canEdit={canEdit}
            />;
    }
}

export default compose(withUser, withFirebase)(EventBase);