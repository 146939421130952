import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    text: {
        display: "inline-block",
        width: "100px"
    }
}));

export default function AlignItemsList({ users, checked, handleSortChange}) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(-1);

    const handleClickOpen = e => {
        setOpen(e);
    };

    const handleClose = () => {
        setOpen(-1);
    };

    return (
        <React.Fragment>
            
            <FormControlLabel
                control={
                    <Switch
                    checked={checked}
                    onChange={handleSortChange}
                />
                }
                label="Nach Geburtsdatum sortieren"
            />
            <List className={classes.root}>
                {users.map((u, i) => <React.Fragment key={i}>
                    <ListItem button onClick={() => handleClickOpen(i)} alignItems="flex-start">
                        <ListItemAvatar>
                            {i === 0 || users[i - 1].firstChar !== u.firstChar ? <Avatar>{u.firstChar}</Avatar> : <div></div>}
                        </ListItemAvatar>
                        <ListItemText
                            primary={u.firstname + " " + u.lastname}
                            secondary={<span>{"eMail: " + u.email}<br />{"Geburtstag: " + (u.birthday ? u.birthday.toLocaleDateString('de-DE') : null)}</span>}
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </React.Fragment>)}
            </List>
            {open !== -1 ? <Dialog
                open={open !== -1}
                onClose={handleClose}>
                <DialogTitle>{users[open].firstname + " " + users[open].lastname}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span className={classes.text}>Vorname:</span>{users[open].firstname}<br />
                        <span className={classes.text}>Nachname:</span>{users[open].lastname}<br />
                        <span className={classes.text}>eMail:</span><a href={"mailto:"+users[open].email}>{users[open].email}</a><br />
                        <span className={classes.text}>Geburtstag:</span>{users[open].birthday ? users[open].birthday.toLocaleDateString('de-DE') : null}<br />
                        <span className={classes.text}>Telefon:</span>{users[open].phone}<br />
                        <span className={classes.text}>Handy:</span>{users[open].mobilePhone}<br />
                        <span className={classes.text}>Straße:</span>{users[open].street}<br />
                        <span className={classes.text}>Ort:</span>{users[open].city}<br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Schliessen
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </React.Fragment>
    );
}