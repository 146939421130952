import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { red, green } from '@material-ui/core/colors';

export default function Requests({ requests, acceptUser, deleteUser }) {
    return (
        <List dense>
            {requests.map(r => <ListItem key={r.uid}>
                <ListItemText
                    primary={r.firstname + " " + r.lastname}
                    secondary={r.verein}
                />
                <ListItemSecondaryAction>
                    <IconButton onClick={() => acceptUser(r.uid)} edge="end" aria-label="delete">
                        <CheckIcon style={{ color: green[500] }} />
                    </IconButton>
                    <IconButton onClick={() => deleteUser(r.uid)} edge="end" aria-label="delete">
                        <DeleteIcon style={{ color: red[500] }} />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            )}
        </List>
    );
}