import React from 'react';
import './App.css';
import { withAuthentication } from './components/session';
import Navigation from './components/navigation';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Routing from './components/routing';

const theme = createMuiTheme({});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router className="App">
        <Navigation title="SFG Manching">
          <Routing />
        </Navigation>
      </Router >
    </ThemeProvider>
  );
}

export default withAuthentication(App);
