import React from 'react';
import Mailrobot from './mailrobot';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import LinearProgress from '@material-ui/core/LinearProgress';

class MailrobotBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allgemein: false,
            alle_admin: false,
            test: false,
            vorstand: false,
            jugend: false,
            segelflug: false,
            motorflug: false,
            ultraleicht: false,
            flugleiter: false,
            kontaktformular: false,
            loading: true
        };

        const { email } = props.user;
        this.docRef = null;
        if (email) {
            this.docRef = props.firebase.db.collection("mailrobot").doc(email);
        }
    }

    componentDidMount() {
        if (this.docRef) {
            this.listener = this.docRef.onSnapshot((doc) => {
                const data = doc.data() || {};

                this.setState({
                    ...data,
                    loading: false
                });
            });
        }
    }

    componentWillUnmount() {
        this.listener();
    }

    handleChange = (e) => {
        const key = e.target.name.toLowerCase();
        const value = !this.state[key];
        this.docRef.set({ [key]: value }, { merge: true }).catch(console.log);
    }

    render() {
        const { user } = this.props;
        const mailrobot_admin = user && user.claims && (user.claims.mailrobot_admin || user.claims.admin);

        return this.state.loading ? <LinearProgress /> : <Mailrobot
            state={this.state}
            onChange={this.handleChange}
            mailrobot_admin={mailrobot_admin}
        />;
    }
}

export default compose(withFirebase, withUser)(MailrobotBase);