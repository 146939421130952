import Dienste from './dienste';
import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import LinearProgress from '@material-ui/core/LinearProgress';

class DienstBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            users: [],
            dienste: [],
            detailUser: null
        }

        this.dienstRef = props.firebase.db.collection("dienste");
        this.userRef = props.firebase.db.collection("documents");
    }

    componentDidMount() {
        this.userRef.doc("dienstusers").get().then((doc) => {
            const data = doc.data() || {};
            const users = Object.keys(data).map(k => ({
                id: k,
                name: data[k]
            }));
            this.setState({ users });
        });

        const midnight = new Date();
        midnight.setHours(0, 0, 0, 0);
        this.unsubscribe = this.dienstRef
            .where("date", ">", midnight)
            .orderBy("date", "asc")
            .onSnapshot((querySnapshot) => {
                const dienste = [];
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    dienste.push({
                        ...data,
                        id: doc.id,
                        date: data.date.toDate(),
                    });
                });
                this.setState({ dienste, loading: false });
            });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    saveEntry = (dienst) => {
        dienst.date = this.props.firebase.fromDate(dienst.date);

        if (dienst.id) {
            return this.dienstRef.doc(dienst.id).set(dienst, { merge: true });
        } else {
            return this.dienstRef.add(dienst);
        }
    }

    handleDetailUserOpen = (id) => {
        this.props.firebase.db.collection("users").doc(id).get().then(doc => {
            const data = doc.data() || {};
            this.setState({
                detailUser: data
            })
        }).catch(console.log)
    }

    handleDetailUserClose = () => {
        this.setState({
            detailUser: null
        })
    }

    render() {
        return this.state.loading ?
            <LinearProgress /> :
            <Dienste
                dienste={this.state.dienste}
                users={this.state.users}
                save={this.saveEntry}
                detailUser={this.state.detailUser}
                handleDetailUserOpen={this.handleDetailUserOpen}
                handleDetailUserClose={this.handleDetailUserClose}
                writeAccess={this.props.user.claims.dienste}
            />;
    }
}

export default compose(withUser, withFirebase)(DienstBase);