import React from 'react';
import Button from '@material-ui/core/Button';
import { withFirebase } from '../firebase';
import { compose } from 'recompose';


class LogoutButton extends React.Component {
    onClick = () => {
        this.props.firebase.auth.signOut().then(function () {
        }).catch(function (error) {
            console.log("Logout", error);
        });
    }

    render() {
        return <Button {...this.props} onClick={this.onClick}>{this.props.children}</Button>;
    }
}

export default compose(withFirebase)(LogoutButton);