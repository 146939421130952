import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

export default function NestedList({ download, user, fileList, onDelete, addFolderClick, open, handleClose, handleSubmit, onBlur, onUpload }) {
    const classes = useStyles();
    const writeAccess = user && user.claims && (user.claims.dokumente_admin || user.claims.admin);

    return (
        <div>
            <List className={classes.root}>
                {fileList.map(folder =>
                    <div key={folder.id}>
                        <ListItem button>
                            <ListItemIcon>
                                <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={folder.name} />
                            {writeAccess ?
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => onUpload(folder.path)} edge="end" aria-label="delete">
                                        <CloudUploadIcon />
                                    </IconButton>
                                </ListItemSecondaryAction> : null}
                        </ListItem>
                        <List component="div" disablePadding>
                            {folder.files.map(file =>
                                <ListItem button onClick={() => download(file.path)} key={file.id} className={classes.nested}>
                                    <ListItemIcon>
                                        <InsertDriveFileIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={file.name} />
                                    {writeAccess ?
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={() => onDelete(file.path)} edge="end" aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction> : null}
                                </ListItem>
                            )}
                        </List>
                    </div>
                )}
            </List>
            {writeAccess ?
                <Tooltip title="Neuer Ordner" aria-label="add">
                    <Fab color="secondary" onClick={addFolderClick} className={classes.absolute}>
                        <AddIcon />
                    </Fab>
                </Tooltip> : null}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ordner Erstellen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte gib den Namen des Ordners ein um einen neuen Ordner zu erstellen.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        name="name"
                        fullWidth
                        onBlur={onBlur}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Erstellen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}