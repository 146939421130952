import React from 'react';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'material-ui-phone-number';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import {
    DatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';

const localeFormat = "d MMMM yyyy";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
}));

export default function PersonalData({ onChange, onBlur, handleBirthdayChange, verein, firstname, lastname, phone, mobilePhone, street, city, birthday }) {
    const classes = useStyles();
    const onPhoneChange = e => ({ target: { name: "phone", value: e } });
    const onMobileChange = e => ({ target: { name: "mobilePhone", value: e } });

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField required disabled name="firstname" label="Vorname" value={firstname} onChange={onChange} onBlur={onBlur} />
                <TextField required disabled name="lastname" label="Nachname" value={lastname} onChange={onChange} onBlur={onBlur} />
                <TextField required name="street" label="Straße" value={street} onChange={onChange} onBlur={onBlur} />
                <TextField required name="city" label="PLZ, Ort" value={city} onChange={onChange} onBlur={onBlur} />
                <TextField required disabled name="verein" label="Verein" value={verein} onChange={onChange} onBlur={onBlur} />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                    <DatePicker disabled={!!birthday} name="birthday" label="Geburtstag" value={birthday} format={localeFormat} onChange={handleBirthdayChange} />
                </MuiPickersUtilsProvider>
                <PhoneInput name="phone" value={phone} label="Telefonnummer" defaultCountry={'de'} onChange={onPhoneChange} onBlur={onBlur} />
                <PhoneInput name="mobilePhone" value={mobilePhone} label="Handynummer" defaultCountry={'de'} onChange={onMobileChange} onBlur={onBlur} />
            </div>
        </form>
    );
}