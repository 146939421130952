import AccessRights from './access_rights.js';
import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import LinearProgress from '@material-ui/core/LinearProgress';

class AccessRightsBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            accessRights: []
        }

        this.colRef = props.firebase.db.collection("accessRights");
    }

    componentDidMount() {
        this.updateList();
    }

    handleToggle = (index, right) => {
        const obj = this.state.accessRights[index];
        const newValue = !obj[right];

        const data = {
            [right]: newValue
        }

        if (newValue && right === "dokumente_admin") {
            data.dokumente = true;
        }

        if (newValue && right === "termine_admin") {
            data.termine = true;
        }

        this.colRef.doc(obj.uid).set(data, { merge: true }).then(() => {
            const { accessRights } = this.state;
            accessRights[index] = { ...accessRights[index], ...data };
            this.setState({
                accessRights
            });
        }).catch(err => {
            console.log(err);
        });
    }

    updateList = () => {
        let accessRights = [];
        this.colRef
            .orderBy("lastname", "desc")
            .get()
            .then((querySnapshot) => {
                return querySnapshot.forEach(function (doc) {
                    accessRights.push({
                        uid: doc.id,
                        firstChar: doc.data().lastname ? doc.data().lastname.charAt(0).toUpperCase() : "#",
                        ...doc.data()
                    });
                });
            }).then(() => {
                accessRights = accessRights.sort((a,b) => a.lastname.localeCompare(b.lastname));
                this.setState({
                    accessRights,
                    loading: false
                });
            }).catch(function (error) { });
    }

    render() {
        return this.state.loading ?
            <LinearProgress /> :
            <AccessRights
                accessRights={this.state.accessRights}
                handleToggle={this.handleToggle}
            />;
    }
}

export default compose(withUser, withFirebase)(AccessRightsBase);