import React from 'react';
import Settings from './settings';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import LinearProgress from '@material-ui/core/LinearProgress';

class SettingsBase extends React.Component {
    constructor(props) {
        super(props);

        const { email } = props.user;
        this.state = {
            email: email,
            password: ""
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSubmit = () => {
        this.props.firebase.updateLoginCredentials({
            email: this.state.email,
            password: this.state.password
        }).then(() => {
            window.location.reload(false);
        }).catch(err => {
            console.log(err);
            this.setState({ error: true });
        })
    }

    render() {
        return this.state.loading ? <LinearProgress /> : <Settings
            email={this.state.email}
            password={this.state.password}
            error={this.state.error}
            submit={this.onSubmit}
            onChange={this.handleChange}
        />;
    }
}

export default compose(withFirebase, withUser)(SettingsBase);