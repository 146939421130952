import React from 'react'
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import { compose } from 'recompose';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    withRouter
} from "react-router";

const styles = theme => ({
    form: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    },
    header: {
        '& .MuiTypography-root': {
            color: "#ffffff",

        },
        backgroundColor: theme.palette.primary.main
    },
    button: {
        marginRight: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(1)
    }
});

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            username: "",
            password: "",
            loading: false,
            errorUsername: false,
            errorEmail: false,
            errorPassword: false
        };
    }

    onChange = (value, prop) => {
        this.setState({
            [prop]: value,
            errorUsername: false,
            errorEmail: false,
            errorPassword: false
        });
    }

    componentDidUpdate = () => {
        if (this.props.user) {
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            if (!this.props.user.claims.accepted) {
                if(from.pathname === '/finishregister') this.props.history.replace(from);
                else this.props.history.replace('/finishregister');
            }
            else this.props.history.replace(from);
        }
    }

    onRegister = async () => {
        this.setState({
            loading: true
        });

        const { email, username, password } = this.state;

        try {
            await this.props.firebase.startRegistration({ email, username, password })
            await this.props.firebase.auth.signInWithEmailAndPassword(email, password);

            this.setState({
                loading: false
            });
        } catch (error) {
            console.log("caught", error);
            console.log(error.message);

            const errorUsername = error.message === "auth/invalid-uid" || error.message === "auth/uid-already-exists";
            const errorEmail = error.message === "auth/invalid-email" || error.message === "auth/email-already-exists";
            const errorPassword = error.message === "auth/invalid-password";

            this.setState({
                loading: false,
                errorUsername,
                errorEmail,
                errorPassword
            });
        }
    }

    onLogin = () => {
        this.props.history.replace('/');
    }

    render() {
        const { classes } = this.props;
        return <Dialog open>
            <DialogTitle>Registrieren</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Für diese Webseite ist ein Login erforderlich. Hier kannst du dich registrieren, um Zugriff auf den internen Bereich zu erhalten. Da der interne Bereich ausschliesslich Mitgliedern vorbehalten ist, musst du deine eMail-Adresse verifizieren und anschliessend manuell freigeschaltet werden. Es kann daher ein wenig dauern bis du Zugriff auf den internen Bereich bekommst.
                </DialogContentText>
                <Fade in={this.state.loading}><LinearProgress /></Fade>
                <Fade in={!this.state.loading}>
                    <React.Fragment>
                        <TextField
                            required
                            error={this.state.errorUsername}
                            label="Mitgliedsnummer oder Benutzername"
                            className={classes.textField}
                            onChange={(e) => this.onChange(e.target.value, "username")}
                        />
                        <TextField
                            required
                            error={this.state.errorEmail}
                            label="eMail Adresse"
                            className={classes.textField}
                            onChange={(e) => this.onChange(e.target.value, "email")}
                        />
                        <TextField
                            required
                            error={this.state.errorPassword}
                            label="Passwort"
                            type="password"
                            className={classes.textField}
                            onChange={(e) => this.onChange(e.target.value, "password")}
                        />
                    </React.Fragment>
                </Fade>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={this.onLogin}
                    className={classes.button}>
                    Zum Login wechseln</Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={this.state.loading}
                    onClick={this.onRegister}>Registrieren</Button>
            </DialogActions>
        </Dialog>
    }
}

export default compose(withRouter, withStyles(styles), withFirebase, withUser)(Register);