import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import {
    DatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';

const localeFormat = "d MMMM yyyy";

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    textfield: {
        marginBottom: theme.spacing(2)
    }
}));

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export default function Events({ canEdit, date, onDelete, events, error, open, addEvent, handleClose, handleAdd, handleDateChange, onBlur }) {
    const classes = useStyles();

    return (
        <div>
            {events.map((event) =>
                <Card className={classes.card} key={event.uid}>
                    <CardHeader
                        action={canEdit ? 
                            <IconButton onClick={() => onDelete(event.uid)}>
                                <DeleteIcon />
                            </IconButton> : null
                        }
                        title={event.title}
                        subheader={event.date.toDate().toLocaleDateString('de-DE', options)}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {event.description}
                        </Typography>
                    </CardContent>
                </Card>
            )}
            {canEdit ? <Tooltip title="Neu">
                <Fab color="secondary" className={classes.absolute} onClick={addEvent}>
                    <AddIcon />
                </Fab>
            </Tooltip> : null}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Neues Event erstellen</DialogTitle>
                <DialogContent>
                    <TextField
                        error={error}
                        autoFocus
                        margin="dense"
                        name="title"
                        id="title"
                        label="Titel"
                        fullWidth
                        onBlur={onBlur}
                        className={classes.textfield}
                    />
                    <MuiPickersUtilsProvider disablePast className={classes.textfield} utils={DateFnsUtils} locale={deLocale}>
                        <DatePicker disablePast value={date} error={error} name="date" label="Datum" format={localeFormat} onChange={handleDateChange} />
                    </MuiPickersUtilsProvider>
                    <TextField
                        error={error}
                        className={classes.textfield}
                        margin="dense"
                        name="description"
                        id="description"
                        label="Beschreibung"
                        fullWidth
                        multiline
                        rows={4}
                        onBlur={onBlur}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}