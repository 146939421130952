import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const config = {
    apiKey: "AIzaSyDklppRLX2DjlSnJB9IrpPpmqndJ7yxuwk",
    authDomain: "sfg-manching-259515.firebaseapp.com",
    databaseURL: "https://sfg-manching-259515.firebaseio.com",
    projectId: "sfg-manching-259515",
    storageBucket: "sfg-manching-259515.appspot.com",
    messagingSenderId: "979013521758",
    appId: "1:979013521758:web:b9697661c60ec45dd5bfcf",
    measurementId: "G-67K5PX8WE2"
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.auth.languageCode = 'de';
        this.db = app.firestore();
        this.storage = app.storage();

        const functions = app.app().functions('europe-west2');

        this.getEmail = functions.httpsCallable('authentication-getEmail');
        this.startRegistration = functions.httpsCallable('authentication-startRegistration');
        this.finishRegistration = functions.httpsCallable('authentication-finishRegistration');
        this.updateLoginCredentials = functions.httpsCallable('authentication-updateLoginCredentials');

        this.fromDate = app.firestore.Timestamp.fromDate;
    }
}

export default Firebase;