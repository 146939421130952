import React from 'react'
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import { compose } from 'recompose';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    withRouter
} from "react-router";

const styles = theme => ({
    form: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    },
    header: {
        '& .MuiTypography-root': {
            color: "#ffffff",

        },
        backgroundColor: theme.palette.primary.main
    },
    button: {
        marginRight: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(1)
    }
});

class FinishRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            firstname: "",
            lastname: "",
            verein: "",
            loading: false,
            error: false,
            done: false
        };
    }

    componentDidMount = () => {
        const { search } = this.props.location;
        if (!search) return;
        var vars = search.replace('?', '').split("&");
        let token = "";
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === "token") { token = pair[1]; }
        }

        if (token.length > 1) {
            this.setState({
                token
            })
        }
    }

    onChange = (value, prop) => {
        this.setState({
            [prop]: value,
            loading: false,
            error: false
        });
    }

    onFinish = async () => {
        this.setState({
            loading: true
        });

        const { firstname, lastname, verein, token } = this.state;

        try {
            await this.props.firebase.finishRegistration({ firstname, lastname, verein, token });

            this.setState({
                loading: false,
                done: true,
                token: ""
            });
        } catch (error) {
            console.log(error);

            this.setState({
                error: true
            });
        }
    }

    render() {
        const { classes, user } = this.props;
        const emailVerified = user && user.emailVerified;

        return <Dialog open>
            <DialogTitle>Registrierung abschliessen</DialogTitle>
            <DialogContent>
                {
                    this.state.token.length > 1 && !emailVerified ?
                        <React.Fragment>
                            <DialogContentText>
                                Bitte gib noch deinen Vorname, Nachname und Verein ein, damit wir deine Registrierungsanfrage abschliessen können.
                        </DialogContentText>
                            <TextField
                                required
                                error={this.state.error}
                                label="Vorname"
                                className={classes.textField}
                                onChange={(e) => this.onChange(e.target.value, "firstname")}
                            />
                            <TextField
                                required
                                error={this.state.error}
                                label="Nachname"
                                className={classes.textField}
                                onChange={(e) => this.onChange(e.target.value, "lastname")}
                            />
                            <TextField
                                required
                                error={this.state.error}
                                label="Verein"
                                className={classes.textField}
                                onChange={(e) => this.onChange(e.target.value, "verein")}
                            />
                        </React.Fragment> :
                        this.state.done || emailVerified ?
                            <DialogContentText>
                                Du wurdest erfolgreich verifiziert. Du musst nun noch manuell freigeschaltet werden bevor du den internen Bereich nutzen kannst.
                        </DialogContentText> :
                            <DialogContentText>
                                Wir haben eine eMail an deine angegebene Adresse geschickt. Bitte klick auf den darin enthaltenen Link um deine Registrierung abschliessen zu können.
                        </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                {this.state.token.length > 1 ? <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={this.state.loading}
                    onClick={this.onFinish}>Fertig</Button> : null}

            </DialogActions>
        </Dialog>
    }
}

export default compose(withRouter, withStyles(styles), withFirebase, withUser)(FinishRegister);