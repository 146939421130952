import React from 'react';
import PersonalData from './personaldata';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import LinearProgress from '@material-ui/core/LinearProgress';

class PersonalDataBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            phone: "",
            mobilePhone: "",
            street: "",
            city: "",
            verein: "",
            loading: true
        };

        const { uid } = props.user;
        this.docRef = null;
        if (uid) {
            this.docRef = props.firebase.db.collection("users").doc(uid);
        }
    }

    componentDidMount() {
        if (this.docRef) {
            this.listener = this.docRef.onSnapshot((doc) => {
                const data = doc.data() || {};

                this.setState({
                    ...data,
                    birthday: data.birthday ? data.birthday.toDate() : null,
                    loading: false
                });
            });
        }
    }

    componentWillUnmount() {
        this.listener();
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleBirthdayChange = (e) => {
        if (e) {
            this.docRef.set({
                birthday: this.props.firebase.fromDate(e)
            }, { merge: true }).catch(err => {
                console.log("error", err);
            });
        }
    }

    handleBlur = (e) => {
        this.docRef.set({
            [e.target.name]: e.target.value
        }, { merge: true }).catch((err) => {
            console.log("error", err);
        });

        if (this.state.firstname.length > 1 &&
            this.state.lastname.length > 1 &&
            (e.target.name === "firstname" || e.target.name === "lastname")) {
            this.props.firebase.auth.currentUser.updateProfile({
                displayName: this.state.lastname + "; " + this.state.firstname
            }).catch(err => {
                console.log(err);
            });
        }
    }

    render() {
        return this.state.loading ? <LinearProgress /> : <PersonalData
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            phone={this.state.phone}
            mobilePhone={this.state.mobilePhone}
            street={this.state.street}
            birthday={this.state.birthday}
            city={this.state.city}
            loading={this.state.loading}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            handleBirthdayChange={this.handleBirthdayChange}
            verein={this.state.verein}
        />;
    }
}

export default compose(withFirebase, withUser)(PersonalDataBase);