import Requests from './requests';
import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import LinearProgress from '@material-ui/core/LinearProgress';

class RequestsBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            requests: []
        }

        this.colRef = props.firebase.db.collection("accessRights");
        this.updateList();
    }

    updateList = () => {
        const requests = [];
        this.colRef
            .where('accepted', '==', false)
            .get()
            .then((querySnapshot) => {
                return querySnapshot.forEach(function (doc) {
                    requests.push({
                        ...doc.data(),
                        uid: doc.id
                    });
                });
            }).then(() => {
                this.setState({
                    requests,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

    acceptUser = (uid) => {
        this.colRef.doc(uid).set({
            accepted: true
        }).then(() => {
            const { requests } = this.state;
            const tmp = requests.filter(r => r.uid !== uid);
            this.setState({
                requests: tmp
            });
        }).catch(err => {
            console.log(err);
        });
    }

    deleteUser = (uid) => {
        this.colRef.doc(uid).delete().then(() => {
            const { requests } = this.state;
            const tmp = requests.filter(r => r.uid !== uid);
            this.setState({
                requests: tmp
            });
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        return this.state.loading ?
            <LinearProgress /> :
            <Requests
                requests={this.state.requests}
                acceptUser={this.acceptUser}
                deleteUser={this.deleteUser}
            />;
    }
}

export default compose(withFirebase)(RequestsBase);