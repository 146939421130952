import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function PersonalData({ onChange, state, mailrobot_admin }) {
    return (<div>
        <p>
            Empfangen: Du kannst auf dieser Seite auswählen, zu welchen Kategorien du Mails empfangen möchstest. Klicke dazu einfach auf die Schalter neben den Kategorien um Mails der entsprechenden Kategorie zu empfangen - oder eben auch nicht.
        </p>
        <p>
            Senden: Mit dem Mailrobot können Mails an alle Mitglieder geschickt werden. Um den Mailrobot zu nutzen schickst du eine Mail an <a href="mailto:mailrobot@sfg-manching.de">mailrobot@sfg-manching.de</a>. Diese wird dann vom Mailrobot automatisch an alle Mitglieder verschickt. Damit nicht Alle alle Mails bekommen müssen, verschickt der Mailrobot die Mails anhand von Kategorien. Die Kategorie deiner Mail erkennt der Mailrobot am Betreff deiner Mail. Schreib als allererstes im Betreff die gewünschte Kategorie, dann erkennt der Mailrobot wohin er deine Mails senden soll. Der Mailrobot checkt die Mails nur alle paar Minuten. Hab also etwas Geduld bis er deine Mail weiterleitet.
        </p>
        <FormControl component="fieldset">
            <FormLabel component="legend">Wähle aus, welche Mailrobots du empfangen willst.</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={<Switch
                        checked={state.allgemein}
                        onChange={onChange}
                        name="Allgemein"
                    />}
                    label="Allgemein"
                />
                <FormControlLabel
                    control={<Switch
                        checked={state.jugend}
                        onChange={onChange}
                        name="Jugend"
                    />}
                    label="Jugend"
                />
                <FormControlLabel
                    control={<Switch
                        checked={state.segelflug}
                        onChange={onChange}
                        name="Segelflug"
                    />}
                    label="Segelflug"
                />
                <FormControlLabel
                    control={<Switch
                        checked={state.motorflug}
                        onChange={onChange}
                        name="Motorflug"
                    />}
                    label="Motorflug"
                />
                <FormControlLabel
                    control={<Switch
                        checked={state.ultraleicht}
                        onChange={onChange}
                        name="Ultraleicht"
                    />}
                    label="Ultraleicht"
                />
                <FormControlLabel
                    control={<Switch
                        checked={state.flugleiter}
                        onChange={onChange}
                        name="Flugleiter"
                    />}
                    label="Flugleiter"
                />
            </FormGroup>
            {mailrobot_admin ?
                <FormGroup>
                    <FormControlLabel
                        control={<Switch
                            checked={state.test}
                            onChange={onChange}
                            name="Test"
                        />}
                        label="Test"
                    />
                    <FormControlLabel
                        control={<Switch
                            checked={state.vorstand}
                            onChange={onChange}
                            name="Vorstand"
                        />}
                        label="Vorstand"
                    />
                    <FormControlLabel
                        control={<Switch
                            checked={state.kontaktformular}
                            onChange={onChange}
                            name="Kontaktformular"
                        />}
                        label="Kontaktformular"
                    />
                </FormGroup> : null}
        </FormControl></div>
    );
}