import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import {
    DatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';

const localeFormat = "d MMMM yyyy";
const localeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    text: {
        display: "inline-block",
        width: "200px"
    },
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    textfield: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));

const dienstjobs = [
    ["Flugleiter", "flugleiter"],
    ["Flugleiter 2", "flugleiter2"],
    ["Startleiter", "startleiter"],
    ["Windenfahrer", "windenfahrer"],
    ["Windenschüler", "windenschueler"],
    ["Fluglehrer", "fluglehrer"]
];

export default function Dienste({ users, dienste, save, detailUser, handleDetailUserClose, handleDetailUserOpen, writeAccess }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [dienst, setDienst] = React.useState({});
    const [error, setError] = React.useState(false);

    const handleClickOpen = (i) => {
        if (i === -1) {
            const data = { date: new Date() };
            dienstjobs.forEach(d => {
                data[d[1]] = { id: "", name: "" };
            });
            setDienst(data);
        } else setDienst(dienste[i]);

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        save(dienst).then(() => {
            setOpen(false);
        }).catch((err) => {
            setError(true);
        });
    }

    const handleSetDienst = (name, value, action) => {
        const user = users.filter(u => u.name === value);

        setError(false);

        if (action === "reset" && user.length === 1) setDienst({ ...dienst, [name]: user[0] });
        if (action === "reset" && name === "date") setDienst({ ...dienst, date: value });
    }

    return (
        <React.Fragment>
            {writeAccess ? <Tooltip title="Neu">
                <Fab color="secondary" onClick={() => handleClickOpen(-1)} className={classes.absolute}>
                    <AddIcon />
                </Fab>
            </Tooltip> : null }
            {detailUser !== null ? <Dialog
                open={detailUser !== null}
                onClose={handleDetailUserClose}>
                <DialogTitle>{detailUser.firstname + " " + detailUser.lastname}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span className={classes.text}>Vorname:</span>{detailUser.firstname}<br />
                        <span className={classes.text}>Nachname:</span>{detailUser.lastname}<br />
                        <span className={classes.text}>eMail:</span><a href={"mailto:"+detailUser.email}>{detailUser.email}</a><br />
                        <span className={classes.text}>Telefon:</span>{detailUser.phone}<br />
                        <span className={classes.text}>Handy:</span>{detailUser.mobilePhone}<br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDetailUserClose} color="primary" autoFocus>
                        Schliessen
                    </Button>
                </DialogActions>
            </Dialog> : null }
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Neue Einteilung erstellen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Hier kannst du eine neue Einteilung erstellen.
                    </DialogContentText>
                    <MuiPickersUtilsProvider disablePast className={classes.textfield} utils={DateFnsUtils} locale={deLocale}>
                        <DatePicker error={error} disablePast value={dienst.date} name="date" label="Datum" format={localeFormat} onChange={date => handleSetDienst("date", date, "reset")} />
                    </MuiPickersUtilsProvider>
                    {dienstjobs.map(d => <Autocomplete
                        key={d[1]}
                        disableClearable
                        options={users}
                        getOptionLabel={(option) => option.name || ""}
                        onInputChange={(e, v, t) => handleSetDienst(d[1], v, t)}
                        value={dienst[d[1]]}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={d[0]}
                                error={error}
                                margin="normal"
                                name={d[1]}
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
            <div>
                {dienste.map((dienst, i) => <Card key={dienst.id} className={classes.card}>
                   <CardHeader
                        action={writeAccess ? <IconButton onClick={() => handleClickOpen(i)}>
                                <EditIcon />
                            </IconButton> : null }
                        title={dienst.date.toLocaleDateString('de-DE', localeOptions)}
                    />
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {dienstjobs.map(d => <React.Fragment key={d[1]}>
                                <span className={classes.text}>{d[0]}:</span><Button size="small" onClick={() => handleDetailUserOpen(dienst[d[1]].id)}>{dienst[d[1]].name}</Button><br />
                            </React.Fragment>)}
                        </Typography>
                    </CardContent>
                </Card>)}
            </div>
        </React.Fragment>
    );
}