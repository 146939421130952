import React from "react";
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { withUser } from "../session";

import Login from '../authentication/login';
import Register from '../authentication/register';
import PasswordForget from '../authentication/passwordforget';
import FinishRegister from '../authentication/finishregister';

import Users from '../users';
import PersonalData from '../personaldata';
import Events from '../events';
import Documents from '../documents';
import Dienste from '../diensteinteilung';
import RegisterRequests from '../registerrequests';
import AccessRights from '../access_rights';
import Home from '../home';
import Mailrobot from '../mailrobot';
import Settings from '../settings';

function Routing({user}) {
    return (
        <Switch>
            <Route path="/register">
                <Register />
            </Route>
            <Route path="/passwordforget">
                <PasswordForget />
            </Route>
            <PrivateRoute user={user} path="/finishregister">
                <FinishRegister />
            </PrivateRoute>
            <PrivateRoute user={user} path="/users">
                <Users />
            </PrivateRoute>
            <PrivateRoute user={user} path="/personaldata">
                <PersonalData />
            </PrivateRoute>
            <PrivateRoute user={user} path="/events">
                <Events />
            </PrivateRoute>
            <PrivateRoute user={user} path="/documents">
                <Documents />
            </PrivateRoute>
            <PrivateRoute user={user} path="/dienste">
                <Dienste />
            </PrivateRoute>
            <PrivateRoute user={user} path="/registerrequests">
                <RegisterRequests />
            </PrivateRoute>
            <PrivateRoute user={user} path="/accessrights">
                <AccessRights />
            </PrivateRoute>
            <PrivateRoute user={user} path="/mailrobot">
                <Mailrobot />
            </PrivateRoute>
            <PrivateRoute user={user} path="/home">
                <Home />
            </PrivateRoute>
            <PrivateRoute user={user} path="/settings">
                <Settings />
            </PrivateRoute>
            <Route path="/">
                <Login />
            </Route>
        </Switch>
    );
}

function PrivateRoute({ children, user, ...rest }) {
    return (<Route
            {...rest}
            render={({ location }) =>
                user ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default withUser(Routing);