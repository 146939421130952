import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../firebase';
import { compose } from 'recompose';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                user: null
            };
        }

        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if (authUser) {
                        const { email, displayName, uid, emailVerified } = authUser;

                        authUser.getIdTokenResult().then(tokens => {
                            this.setState({
                                user: {
                                    email, emailVerified, displayName, uid, claims: tokens.claims, authUser
                                }
                            });
                        });
                    } else {
                        this.setState({
                            user: null
                        })
                    }
                },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return <AuthUserContext.Provider value={this.state.user}>
                <Component {...this.props} />
            </AuthUserContext.Provider>;
        }
    }
    return compose(withFirebase)(WithAuthentication);
};

export default withAuthentication;