import React from 'react'
import { withFirebase } from '../firebase';
import { withUser } from '../session';
import { compose } from 'recompose';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    withRouter
} from "react-router";

const styles = theme => ({
    form: {
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    },
    header: {
        '& .MuiTypography-root': {
            color: "#ffffff",

        },
        backgroundColor: theme.palette.primary.main
    },
    button: {
        marginRight: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(1)
    }
});

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            errorEmail: false,
            errorPassword: false
        };
    }

    onChange = (value, prop) => {
        this.setState({
            [prop]: value,
            errorEmail: false,
        });
    }

    onPasswordForget = async () => {
        this.setState({
            loading: true,
        });
        const { email } = this.state;

        try {
            var actionCodeSettings = {
                // After password reset, the user will be give the ability to go back
                // to this page.
                url: 'https://intern.sfg-manching.de/',
                handleCodeInApp: false
            };
            await this.props.firebase.auth.sendPasswordResetEmail(email, actionCodeSettings)
            this.setState({
                loading: false,
                success: true
            });

            await new Promise((resolve, reject) => {
                setTimeout(resolve, 1000);
            });

            this.props.history.replace('/login');
        } catch (error) {
            console.log(error);
            this.setState({
                errorEmail: true,
                loading: false
            });
        }
    }

    onLogin = () => {
        this.props.history.replace('/');
    }

    componentDidUpdate = () => {
        if (this.props.user) {
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            if (!this.props.user.claims.accepted) {
                if (from.pathname === '/finishregister') this.props.history.replace(from);
                else this.props.history.replace('/finishregister');
            }
            else this.props.history.replace(from);
        }
    }

    render() {
        const { classes } = this.props;

        return <Dialog open>
            <DialogTitle>Passwort vergessen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Falls du dein Passwort vergessen hast, kannst du hier eine Mail an dich schicken, um dein Passwort zurücksetzen zu können.
                </DialogContentText>
                <Fade in={this.state.loading}><LinearProgress /></Fade>
                <Fade in={!this.state.loading}>
                    <React.Fragment>
                        <TextField
                            required
                            error={this.state.errorEmail}
                            label="eMail Adresse"
                            className={classes.textField}
                            onChange={(e) => this.onChange(e.target.value, "email")}
                        />
                    </React.Fragment>
                </Fade>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={this.onLogin}
                    className={classes.button}>
                    Zum Login wechseln</Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={this.state.loading || this.state.success}
                    onClick={this.onPasswordForget}>Email senden</Button>
            </DialogActions>
        </Dialog>
    }
}

export default compose(withRouter, withStyles(styles), withFirebase, withUser)(Register);
